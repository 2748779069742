import React from 'react';
import Helmet from 'react-helmet';
import { articleStyle } from '../components/Layout/styles';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

export default class BlogPostTemplate extends React.Component {
  render() {
    const { frontmatter, html } = this.props.data.markdownRemark;
    const author = 'Dorian Puła';

    return (
      <Layout>
        <div className='span7'>
          <Helmet>
            <title>{frontmatter.title}</title>
          </Helmet>
          <article css={articleStyle}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <h1 css={{ marginBottom: '5px' }}>{frontmatter.title}</h1>
              <span
                css={{
                  fontStyle: 'italic',
                  color: '#CCCCCC',
                  fontSize: '16pt',
                  paddingBottom: '10px',
                }}
              >
                Posted by {author} on {frontmatter.date}{' '}
              </span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </article>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query BlogPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
